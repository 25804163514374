import axios from "axios";
import { useAuth } from "../context/AuthContext";

const URI = "https://api-old.ld.okroom.ru/support-user/";
// eslint-disable-next-line no-unused-vars
const INVALID_TOKEN = 401;

export const usePostRequest = () => {
  const { authToken } = useAuth();
  return async (method, postData) => {
    console.log(authToken);
    const headers = {
      "Content-Type": "application/json",
      accept: "*/*",
    };
    if (authToken) {
      headers.Authorization = "Bearer " + authToken;
    }
    try {
      const response = await axios.post(URI + method, postData, {
        headers,
      });
      return { success: true, data: response.data };
    } catch (e) {
      return {
        success: false,
        errorCode: e.response?.status,
        msg: e.response?.data?.error_msg,
      };
    }
  };
};

export const useGetRequest = () => {
  const { authToken } = useAuth();
  return async (method, params = {}) => {
    const headers = {};
    console.log(authToken);
    if (authToken) {
      headers.Authorization = "Bearer " + authToken;
    }
    try {
      const response = await axios.get(URI + method, {
        headers: headers,
        params: params,
      });
      return { success: true, data: response.data };
    } catch (e) {
      return { success: false, errorCode: 401 };
    }
  };
};
